<template>
  <div
    class="bg_col_fff bor_rad_8 box_sha_0_0_8_8_black_80_per p_16 m_0_16 m_t_16"
  >
    <div class="dis_flex ali_it_cen">
      <div class="flex_1 font_16 font_bold">省长列表</div>

      <!-- 日期选择 -->
      <div class="dis_flex ali_it_cen">
        <div
          class="dis_flex ali_it_cen"
          @click="
            filterForm = {
              ...filterForm,
              tempTimeStart: filterForm.tempTimeStart || new Date(),
              timeStep: 0,
              timeDialogShow: true,
            }
          "
        >
          <img
            class="w_16 h_16 dis_flex"
            src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220714/TsvtFIIYzRzkBuSudCrx.png"
          />

          <div class="font_12 col_2396F5 m_l_4">
            {{ filterForm.timeStartStr || '开始日期' }}
          </div>

          <div class="font_12 m_0_8">-</div>

          <div class="font_12 col_2396F5">
            {{ filterForm.timeEndStr || '结束日期' }}
          </div>

          <!--  -->
        </div>

        <!-- 清除 -->
        <img
          v-show="filterForm.timeStartStr"
          class="w_16 h_16 dis_flex m_l_4"
          src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220705/sYOxRslRIFJplIZHjJAB.png"
          @click="
            filterForm = {};
            getList();
          "
        />

        <!--  -->
      </div>

      <!-- 日期选择 弹窗 -->
      <van-popup v-model="filterForm.timeDialogShow" position="bottom">
        <van-datetime-picker
          v-if="filterForm.timeStep == 0"
          v-model="filterForm.tempTimeStart"
          type="date"
          title="开始日期"
          key="开始日期"
          @confirm="
            filterForm = {
              ...filterForm,
              timeStep: 1,
            }
          "
          @cancel="filterForm = { ...filterForm, timeDialogShow: false }"
        />

        <van-datetime-picker
          v-if="filterForm.timeStep == 1"
          v-model="filterForm.tempTimeEnd"
          type="date"
          title="结束日期"
          key="结束日期"
          :min-date="filterForm.tempTimeStart"
          @confirm="
            (v) => {
              filterForm = {
                ...filterForm,
                timeStart: moment(filterForm.tempTimeStart).format(
                  'YYYY-MM-DD 00:00:00'
                ),
                timeStartStr: moment(filterForm.tempTimeStart).format(
                  'YYYY-MM-DD'
                ),
                timeEnd: moment(v).format('YYYY-MM-DD 23:59:59'),
                timeEndStr: moment(v).format('YYYY-MM-DD'),
                timeDialogShow: false,
              };
              getList();
            }
          "
          @cancel="filterForm = { ...filterForm, timeStep: 0 }"
        />

        <!--  -->
      </van-popup>

      <!--  -->
    </div>

    <!-- 面包屑 -->
    <!-- 1.0.2暂时屏蔽 -->
    <!-- <div class="flex_1 dis_flex ali_it_cen white_space_nowrap m_t_8">
      <img
        class="w_24 h_24 dis_flex"
        src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220628/eTuDKjmGGttJRpDpbDJO.png"
      />

      <div class="m_l_8 dis_flex ali_it_cen">
        <div
          class="font_14 col_2396F5 cur_pot"
          @click="
            currentBrokerArr = [];
            getList(true);
          "
        >
          全部
        </div>

        <div
          v-for="(item, index) in currentBrokerArr"
          :key="index"
          @click="
            currentBrokerArr = currentBrokerArr.splice(0, index);
            brokerSelected(index, item);
          "
        >
          <span class="m_0_8">/</span>
          <span class="font_14 col_2396F5 cur_pot">{{ item.brokerName }}</span>
        </div> -->

    <!--  -->
    <!-- </div> -->

    <!--  -->
    <!-- </div> -->

    <!-- 表格 -->
    <div class="m_t_16">
      <!-- 列表 -->
      <vxe-table
        :loading="loading"
        :data="list"
        border
        resizable
        align="center"
      >
        <vxe-column
          :title="`${
            ['省长', '合伙人', '经纪人'][currentBrokerArr.length]
          }名称`"
          min-width="112"
          fixed="left"
        >
          <template #default="{ row }">
            <!-- 1.0.2暂时屏蔽 -->
            <!-- <div
              v-if="currentBrokerArr.length < 1"
              class="col_2396F5 cur_pot"
              @click="brokerSelected(currentBrokerArr.length, row)"
            >
              {{ row.brokerName || '-' }}
            </div>
            <div v-else>{{ row.brokerName || '-' }}</div> -->
            {{ row.brokerName || '-' }}
          </template>
        </vxe-column>

        <vxe-column
          v-if="currentBrokerArr.length < 1"
          title="合伙人数量"
          min-width="96"
        >
          <template #default="{ row }">
            {{ columnAmount(row.leaderReigsterCount, { fixed: 0 }) }}
          </template>
        </vxe-column>

        <vxe-column title="经纪人数量" min-width="96">
          <template #default="{ row }">
            {{ columnAmount(row.brokerReigsterCount, { fixed: 0 }) }}
          </template>
        </vxe-column>

        <vxe-column title="新增经纪人" min-width="96">
          <template #default="{ row }">
            {{ columnAmount(row.newReigsterCount, { fixed: 0 }) }}
          </template>
        </vxe-column>

        <!--  -->
      </vxe-table>

      <!--  -->
    </div>

    <!--  -->
  </div>
</template>

<script>
const moment = window.moment;
import { Toast } from 'vant';
import { columnAmount } from '@/utils/tools';

import { brokerDashboardAppRegisterGovernorListPost } from '@/api/index';

const defaultFilterForm = () => {
  return {};
};

export default {
  components: {},
  data() {
    return {
      loading: false,
      filterForm: { ...defaultFilterForm() },
      currentBrokerArr: [],
      list: [],

      //
    };
  },
  computed: {},
  mounted() {
    this.getList();

    //
  },
  methods: {
    moment,
    columnAmount,

    // 项 经纪人 选择
    brokerSelected(index, row) {
      this.currentBrokerArr.splice(index, 1, row);
      this.getList(true);
    },

    // 获取 列表
    async getList() {
      try {
        this.loading = true;

        let params = {
          ...this.filterForm,
        };
        const len = this.currentBrokerArr.length;
        if (len) {
          params.brokerId = this.currentBrokerArr[len - 1].brokerId;
        }

        const data = await brokerDashboardAppRegisterGovernorListPost(params);

        this.list = data;

        //
      } catch (err) {
        Toast(err);
      } finally {
        this.loading = false;
      }

      //
    },

    //
  },
};
</script>
